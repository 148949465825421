export const StorageService = {
  getAccessToken() {
    return _localstorage.getItem('accessToken', true);
  },
  setAccessToken(value) {
    return _localstorage.setItem('accessToken', value, true);
  },
  getAuthRefreshToken() {
    return _localstorage.getItem('refreshToken', true);
  },
  setAuthRefreshToken(value) {
    return _localstorage.setItem('refreshToken', value, true);
  },
  getTermsOfUsageAccepted() {
    return _localstorage.getItem('termsOfUsageAccepted');
  },
  setTermsOfUsageAccepted(value) {
    return _localstorage.setItem('termsOfUsageAccepted', value);
  },
  getPermissionsDenied() {
    return _localstorage.getItem('permissionsDenied');
  },
  setPermissionsDenied(value) {
    return _localstorage.setItem('permissionsDenied', value);
  },
  setIsLandscape(value) {
    return _localstorage.setItem('isLandscape', value, true);
  },
  getIsLandscape(value) {
    return _localstorage.getItem('isLandscape', true);
  },
  get(key, fromSessionStorage = false) {
    return _localstorage.getItem(key, fromSessionStorage);
  },
  set(key, value, saveToSessionStorage = false) {
    return _localstorage.setItem(key, value, saveToSessionStorage);
  }
};

const _localstorage = {
  getItem(key, fromSessionStorage = false) {
    key = generateKey(key);
    const storage = selectStorage(fromSessionStorage);

    const val = storage?.getItem(key);

    if (val === null) return undefined;

    try {
      return JSON.parse(val);
    } catch (error) {
      return val; // if not, simply return the value.
    }
  },

  setItem(key, value, saveToSessionStorage = false) {
    key = generateKey(key);
    const storage = selectStorage(saveToSessionStorage);

    if (value === undefined || value === null) {
      return storage?.removeItem(key);
    }

    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    storage.setItem(key, value);
  }
};

function selectStorage(useSessionStorage) {
  if (typeof window === 'undefined') return null;
  return !useSessionStorage ? localStorage : sessionStorage;
}

function generateKey(key) {
  return '__gg_offerwall__.' + key;
}
